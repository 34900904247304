@import "../../../../../../stylesheets/modules_new/styles/components/my-account/booking-details/download-document";

.download-document-container {
	width: 100%;
	&__doc-detail{
		cursor: pointer;
		&_content .content{
			&__document-name{
				color: $brand-color-27;
				cursor: pointer;
			} 
			&__dropdown-menu {
				color: $brand-color-2;
			}
		}
	}
}
