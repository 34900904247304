.email-booking-summary-container {
			padding: 0.725rem;
			border: 0.7rem solid $brand-color-3;
			margin-bottom: 0.725rem;
		
			&__alert-message{
	   			@include clearfix;
	   			width: 100%;
			}
			&__heading {
				    color: $brand-color-2;
				    font-size: 1.25rem;
				    text-align: center;
				    border-bottom: 0.2rem solid $brand-color-3;
				    padding-bottom: 0.5rem;
				    margin-bottom: 0.5rem;
				
			}
			&__form {
				padding: 0.725rem;
				&_field {
					padding: 0.5rem;
     				width : 100%;
					.field {
						&__field-label {
							width: 18%;
							color: $brand-color-2;
							font-size: 14px;
    						line-height: 1.5;
						}
						
						&__field-value{
							width: 60%;
						    background: none;
						    font-size: 0.875rem;
						    vertical-align: middle;
						    line-height: normal;
						    background-color: $light;
						    border: 0.2rem solid;
						    border-color: $gray-lighter;
							display: inline;
							padding: unset;
						}
					
						&__field-validation-error {
							color : $brand-color-1;
							padding-top: 0.2rem;
    						padding-left: 9.25rem;
						}
					
						&__control-buttons {
							@include clearfix;
							width: 100%;
							margin: 0 auto;
							&_send-email{
								@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
								padding : 0.3125rem;
								font-weight: 700;
								width: 20%;
								float: right;
								margin-right: 0.3125rem;
							}
						}
						&__diners-field-separator{
							float: left;
    						padding-top: inherit;
    						margin-top: 2%;
						}
						&__diners-fields{
							width: 80%;
    						float: left;
						}
						&__diners-field-label{
							float: left;
    						padding-top: inherit;
    						margin-top: 2%;
							width: 18%;
							color: $brand-color-2;
							font-size: 14px;
    						line-height: 1.5;
						}
					}
				}
				.cash-diners-details{
					padding-right: unset;
					padding-left: 0.2rem;
					.cash-diners-fieldset{
						@include clearfix;
						width: 25%;
						display: grid;
						&__values{
							line-height: 2rem;
							input[type="text"] {
								width: 100%;
								padding: 0.5rem 0rem;
							}
						}
					}
				}
			}
}
