.pnr-details {
	&__details-block {
		font-size: 0.875rem;
		@include clearfix;
		width: 100%;
		
		&_title {
			float: left;
			color: $brand-color-2;
		}
		&_separator {
			float: left;
			color: $brand-color-2;
			margin-left: .25rem;
			margin-right: .25rem;
		}
		&_value {
			float: left;
		}
		&_ttl-flag{
			color: $brand-color-1;
		}
		&_ttl-remark{
			display: inline-block;
			margin-left: .3rem;
		}
	}
	
	&__extra-info-icon {
  		font-size: 1rem;
  		color: $brand-color-2;
  		padding-left: 0.5rem;
	}
	
	&__flexi-info-icon {
    	font-size: 1.3rem;	
    	color: $brand-color-2;
    	padding-left: 1rem;
	}
}