@import "../../../../../stylesheets/modules_new/styles/components/my-account/booking-details/_send-email-gv-dv";

.send-email-container {
			&__form {
				&_field {
					.field {
						&__field-label {
							width: 18%;
							color: $brand-color-2;
							font-size: $font-size-h4;
    						line-height: $line-height;
						}
						
						&__field-value{
							width: 60%;
							display: inline;
                            padding: unset;
						}
					
						&__field-validation-error {
							padding-left: 20%;
						}
					
						&__control-buttons {
							&_send-email{
								width: 20%;
							}
						}
					}
				}
			}
}
