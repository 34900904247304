@use "sass:math";

.my-profile__page {
  @include create-main-wrapper;
  // @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;

  // @include create-row-card-footer;
  
  &_main {
  	margin: 0;
  	padding: 0;
  }
  
  .country-selector__wrapper {
  	padding: 0;
  }
  
  &_card {
    box-shadow: $row-shadow-color;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      border-bottom: 1px solid $brand-color-2;
      margin-bottom: $base-margin*0.5;
      padding-left:0px;
      font-size: 20px;
    }
    .header_text {
      margin-right: $base-margin;
    }
    &_footer {
      border-top: 1px solid $brand-color-2;
      padding-top: math.div($base-padding, 3);
      padding-bottom: math.div($base-padding, 3);
    }
    .footer_text {
      margin-bottom: 0;
    }
    &_container{
      padding:0.9375ream;
      margin:0.375rem;
    }
    &_content{
		
	  @include create-review-nav;
	  
	  .nav>li>a {
	  	padding: 0.625rem 1.375rem;
	  }
	  
      .content__header{
        @include clearfix;
        padding: .375rem .9375rem;
	    border: 3px solid $brand-color-3;
	      &_heading{
	          float:left;
	        }
	        &_button{
	          float:right;
	        }
      }
      
      .content__wrapper {
        
        &_freq-flier-save-button {
        	width: 50%;
        	margin-left: 25.925rem;
        	margin-bottom: 0.725rem;
        	
        	>button {
        		margin-top: 1.25rem;
				width: 100%;
				height: 2.125rem;
				color: $light;
				background-color: $brand-color-1;
				text-transform: uppercase;
				font-size: 1.25rem;
        	}
        }
        
        &_add-more-passport {
        	color: #087dc2;
		    font-size: .875rem;
		    cursor: pointer;
		    background-color: #f3f9fd;
		    padding: 5px;
		    margin-bottom: 10px;
		    margin-right: 4px;
        }
        
        &_remove-passport {
        	@include clearfix;
        	background-color: #f3f9fd;
		    padding: 5px;
		    margin-bottom: 10px;
		
    		.remove-passport {
	    		&__passport-index {
	    			float: left;
				    color: #ec2227;
				    font-size: .875rem;
	    		}
	    		
	    		&__remove-button {
	    			float: right;
				    color: #838383;
				    cursor: pointer;
	    		}
    		}
        }
        
          &_add-more-flyer {
        	color: #087dc2;
		    font-size: .875rem;
		    cursor: pointer;
		    background-color: #f3f9fd;
		    padding: 5px;
		    margin-bottom: 10px;
		    margin-right: 4px;
        }
        
        &_remove-flyer {
        	@include clearfix;
        	background-color: #f3f9fd;
		    padding: 5px;
		    margin-bottom: 10px;
		
    		.remove-flyer {
	    		&__flyer-index {
	    			float: left;
				    color: #ec2227;
				    font-size: .875rem;
	    		}
	    		
	    		&__remove-button {
	    			float: right;
				    color: #838383;
				    cursor: pointer;
	    		}
    		}
        }
        
        &_personal-details-container, &_contact-container, &_passport-container, &_preference-container, &_frequent-flyer-container, &_save-button {
        	@include clearfix;
        	margin-bottom: 0.725rem;
        	
        	
        	.frequent-flyer-container {
        		&__airline, &__f-f-number, &__active, &__save {
        			float: left;
        			padding: 5px;
        			width: 25%;
        			
        			&_label {
        				width: 100%;
        			}
        		}
        		
        		&__new-airline, &__new-f-f-number {
        			float: left;
        			padding: 5px;
        			width: 50%;
        			
        			>select {
    					height: 34px;
			        	max-height: 34px;
			        	width: 100%;
			        	background: 0 0;
        			}
        		}
        		
        		&__airline_value {
        			font-size: 15px;
        		}
        		
        		&__active_value {
    			    margin-top: 0.75rem;
    			    width: 20%;
        		}
        		
        		&__save_button {
    				margin-top: 20px;
    				width: 100%;
    				height: 34px;
    				color: $light;
					background-color: $brand-color-1;
					text-transform: uppercase;
        		}
        	}
        	
        	.preference-container {
        		&__seat-preference, &__meal-preference {
        			float: left;
        			padding: 5px;
        			width: 50%;
        			
        			&_label {
        				width: 100%;
        			}
        			
        			&_value {
    					height: 34px;
			        	max-height: 34px;
			        	background: 0 0;
			        	width: 100%;
        			}
        		}
        	}
        	
        	.passport-container {
        		&__passport-number, &__passport-nationality, &__date-of-expiry, &__country-of-issue, &__passport-alert {
        			float: left;
        			padding: 5px;
        			width: 50%;
        		}
        		
        		&__passport-nationality, &__country-of-issue  {
        			.country-selector__wrapper {
        				width: 100%;
			      		> select {
				        	height: 34px;
				        	max-height: 34px;
				        	width: 100%;
				        	background: 0 0;
				        }  			
        			}
        		}
        	}
        	
        	.personal-details-container {
        		&__title, &__first-name, &__last-name, &__gender {
        			float: left;
        			padding: 5px;
        		}
       			
       			&__gender-radio {
       				margin-right: 1rem;
       			}
        		
        		&__title {
        			width: 20%;
        			> select {
			        	height: 34px;
			        	max-height: 34px;
			        	width: 100%;
			        	background: 0 0;
			        }
        		}
        		
				&__date-of-birth, &__nationality, &__place-of-birth{
        			float: left;
        			padding: 5px;
        			width: 50%;
        		}
        		
        		&__relation {
        			float: left;
        			padding: 5px;
        			width: 50%;
    				> select {
        				height: 34px;
			        	max-height: 34px;
			        	width: 100%;
			        	background: 0 0;
        			}
        		}
        		
        		&__nationality {
        			.country-selector__wrapper {
        				width: 100%;
	        			> select {
	        				height: 34px;
				        	max-height: 34px;
				        	width: 100%;
				        	background: 0 0;
	        			}
        			}
        		}
        		
        		&__first-name, &__last-name {
        			width: 40%;
        		}
        	}
        	
        	.contact-container {
        		&__email, &__mobile, &__country, &__city, &__pin-code, &__address, &__source-media-code, &__source-media-code-info {
        			float: left;
        			padding: 5px;
        			width: 50%;
        		}
        		
        		&__city {
        			&_label {
        				width: 100%;
        			}
        			
        			&_value {
    					height: 34px;
			        	max-height: 34px;
			        	background: 0 0;
			        	width: 100%;
        			}
        		
        		}
        		
        		&__country {
	        		.country-selector__wrapper {
	        			width: 100%;
						>select {
							height: 34px;
				        	max-height: 34px;
				        	background: 0 0;
				        	width: 100%;
						}	        		
	        		}
        		}
        		
        		&__email {
        			&_label {
        				width: 100%;
        			}
        			&_value {
        				color: #808080;
        				font-size: 1rem;
    					margin-top: 0.325rem;
        			}
        		}
        		
        		&__address {
        			>textarea {
        				height: 64px;
        			}
        		}
        		&__source-media-code-info {
        			float : right;
        			>textarea {
        				height: 64px;
        			}
        		}
        	}
        	
    		
        }
        
		&_save-button {
			    padding-left: 15px;
    			padding-right: 5px;
			>button {
				float: right;
				font-size: 20px;
				color: $light;
				background-color: $brand-color-1;
				width: 50%;		
				text-transform: uppercase;
			}
		}
        
        
      }

	}
  }
  &_form {
    max-width: 400px;
    margin: 0 auto;
  }
  &_input_wrapper {
    margin-bottom: $base-margin*0.5;
    
    .country-selector__wrapper {
		float: none;
	}
    
  }
  // label,
  // input[type="text"] {
  //   display: block;
  //   width: 100%;
  // }
  label {
    // width: 100%;
    margin-bottom: 0;
    color: $sf-label-color;
    font-size: 0.875rem;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 0.5rem 1rem;
    background: none;
    font-size: 0.875rem;
    vertical-align: middle;
    line-height: normal;
    background-color: $input-bgcolor;
    border: 1px solid;
    border-color: $input-border-color;
    max-height: 34px;
  }
  
  select, textarea {
    width: 100%;
    padding: 0.5rem 1rem;
    background: none;
    font-size: 0.875rem;
    vertical-align: middle;
    line-height: normal;
    background-color: $input-bgcolor;
    border: 1px solid;
    border-color: $input-border-color;
    max-height: 34px;
  }
  
  textarea {
    max-height: 64px;
  }
  
  &_button {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    background-color: $brand-color-1;
    border: none;
    line-height: inherit;
    border-radius: 0;
    background-image: none;
    width: 100%;
    color: $light;
    font-weight: 700;
    &:hover {
      background-color: darken($brand-color-1, 20);
    }
  }
}
.pax {
  &__title {
    width: 22%;
    display: inline-block;
  }
  &__fname {
    width: 77%;
    display: inline-block;
  }
  &__gender {
    float: left;
    width: 33%;
    &_wrapper {
      @include clearfix;
    }
  }
}

.my-profile {
	@include create-main-wrapper;
		
	margin: 1.875rem auto 0;
	
	&_wrapper {
		@include clearfix;
		
		&_sidebar, &_content, &_header {
			float: left;
		}
		
		&_header {
			@include clearfix;
			width: 75%;
			height: 3.875rem;
			
			.header {
				&__title {
					float: left;
					width: 15%;
					font-size: 1.875rem;
					margin-top: 0.9375rem;
				}
				
				&__alert-messages {
					float: left;
					width: 85%;
				}
			}
		}
		
		&_sidebar {
			width: 25%;
			min-height: 1px;
		}
		
		&_content {
			width: 75%;
		}
	}
}