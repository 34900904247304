@mixin create-transition-form_private (){
	&__transition-form {
		&_form {
			padding: 0.725rem;
			border: 10px solid $brand-color-3;
			margin-bottom: 0.725rem;
		}
	
		textarea {
		    width: 50%;
			background: none;
			font-size: 0.875rem;
			vertical-align: middle;
			line-height: normal;
			background-color: $input-bgcolor;
			border: 1px solid;
			border-color: $input-border-color;
		}
	}
	&__heading {
   		color: $brand-color-2;		
   		font-size: 1.25rem;
	    text-align: center;
	    border-bottom: 1px solid $brand-color-3;
	    padding-bottom: 0.5rem;
	    margin-bottom: 0.5rem;
		 &_cancellation-message{
		color:$cancelled-text-color;
		font-size: $font-size-h4;
		text-align: left;
    		font-weight: 900;	
	    }

   			
	}
	&__field {
		width: 100%;
		margin-left: 0.5rem;
		margin-bottom: 0.5rem;
		&_label{
			width:100%;
			margin-bottom: 0.25rem;
	   		color: $sf-label-color;
	   		font-size: 0.875rem;			
		}
		&_value{
			width: 50%;
			padding: 0.5rem 1rem;
			background: none;
			font-size: 0.875rem;
			vertical-align: middle;
			line-height: normal;
			background-color: $input-bgcolor;
			border: 1px solid;
			border-color: $input-border-color;
			position: relative;
		}
		.disabled{
			background-color: $gray-lightest
		}
		&_validation-error{
			color:red;
		}
		&_warning-message{
			color:$brand-color-9;
			font-size: $font-size-h4;
		}
		&_data-block {
			@include clearfix;
			width: 100%;
		}
		&_pnr-multi-tst-cancellation-warning-message{
			text-align: left;
  			color: $brand-color-9;
    		font-size: 0.875rem;
		}
		&_data {
			float: left;
			margin-right: .25rem;
		}
	}
	&__input-group {
		@include clearfix;
		width: 85%;
	}
	&__input-group-addon {
		white-space: nowrap;
	    vertical-align: middle;
	    padding: 9px 12px;
	    font-size: 14px;
	    font-weight: 400;
	    line-height: 1;
	    text-align: center;
	    border: 1px solid #ccc;
	    float: left;
    }	
	&__control-buttons {
		@include clearfix;
		width: 100%;
		margin: 0 auto;
		.control-buttons {
			&__confirm-cancel, &__close, &__revert_reissue, &__save, &__update-to-all {
				@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
				float: left;
				margin-left: 0.5rem;
	    		margin-right: 0.125rem;
	    		margin-top: 0.525rem;
				padding: 0.525rem;
				width: 30%;
			}
		}
	}
}

@mixin  create-transition-form (){ 
	@include create-transition-form_private(); 
}