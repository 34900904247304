@import "../../../../../stylesheets/modules_new/styles/components/air/mini-fare-rules/mini-fare-rules";

%fare-rule-header {
	color: $gray-dark;
}

.mini-fare-rule{
	&__info{
		&_text{
			color: $brand-color-28;
		}
		&_icon{
			color: $royal-red;
		}
	}
}
