.booking__screen {
	padding: 10px;
	@include create-main-wrapper;
  	
  	&_card {
  		margin-top: 10px;
  		width: 100%;
  		
  		.booking-product {
  			padding: 0.725rem 0 1.725rem 0;
  			
  			&__price-breakup{
  				margin: 0.5rem 0;
  				.tax-detail {
					@include create-pricing-content;
				}
  			}
  			&__price-breakup-title,&__addition-information-title{
  				font-size: 1.15rem;
				color: $brand-color-2;
  			}
  			&__addition-information-title{
				margin-top: 0.625rem;
			}
			&__addition-information-content{
				
				border: 2px solid $gray-lighter;
				padding: 0.625rem;
				background-color: $brand-color-4;
				font-size: .875rem;
				font-weight: 400;
			}			
	
	  		&__overview {
	  			@include clearfix;
	  			font-size: 20px;
	  			
	  			&_header {
	  				float: left;
	  				width: 3rem;
	  			}
	  			&_product-details,&_product-details-dp {
	  				float: right;
	  				margin-top: 0.5rem;
	  				width: 45%;
	  				.product-details {
	  					&__amount-contianer,&__amount-contianer-dp {
	  						@include clearfix;
	  						float: right;
	  						
	  						&_price {
		  						float: right;
		  						font-size: 1.25rem;
		  						color: $brand-color-1;
		  						font-family: $font-family-number;
	  						}
	  						&_currency {
	  							float: right;
	  							margin-top: 0.3rem;
	  							margin-right: 0.25rem;
		  						font-size: 0.875rem;
	  						}
	  					}
	  					&__amount-contianer-dp{
	  						width: 100%;
	  					}
	  					&__status {
    						float: right;
    						padding-right: 0.7rem;
    						font-size: 1rem;
    						margin-top: .2rem;
    						width: 54%;
    						word-wrap: break-word;
	  					}
	  					&__multiple-amounts {
	  						color: $brand-color-2;
	  						float: right;
	  						font-size: 1.5rem;
	  				}
	  			}
	  			}
	  			&_product-details-dp{
	  			    width: 25%;
	  			}
	  			&_header-title,&_header-title-dp,&_header-title-transfer,&_header-title-misc,&_header-product-name{
  				    color: $brand-color-2;
				    font-size: 1.5rem;
				    font-weight: 500;
				    float: left;
				    margin-top: 0.3rem;
				    width: 23%;
				    text-overflow: ellipsis;
    				overflow: hidden;
    				white-space: nowrap;
	  			}
	  			&_header-title-dp{
	  				margin-top: 0.4rem;
	  			}
	  			&_header-title-misc{
	  				width: 45%;
	  			}
				&_header-product-name{
					width: 15%;
					font-size: 1rem;
    				margin-top: 10px;
    				margin-left: 5px;
    				color: $brand-color-5;
				}
				&_header-title{
					width: 13%;
				}
	  			&_header-details,&_header-details-non-air,&_header-details-dp,&_header-details-transfer{
	  				width: 20%;
				    float: left;
				    margin-top: 0.7rem;
				    margin-left: 0.7rem;
				    font-size: 0.9rem;
	  			}
	  			&_header-details-non-air{
	  				margin-top: 0.3rem;
	  			}
	  			&_header-details-dp {
				    font-size: 1rem;
				    text-align: center;
				    margin-top: 0.4rem;
				    background-color: $brand-color-3;
				    color: $brand-color-2;
				    padding: .3125rem .9375rem;
				}
				&_header-details-transfer{
					width: 23%;
					.sector-info__separator{
						float: left;
					}
				}
	  			&_sector{
	  				float: left;
	  			}
	  			&_pickup-dropoff-value{
	  				float:left;
	  				margin-right:0.5rem;
	  			}
  			}
  			&__supplier-ref-no{
				font-size: 1rem;
  				&_label {
  					color: $brand-color-2;
  				}
  			}
  			&__lead-pax{
  				&_title {
					font-size: 1.15rem;
  					color: $brand-color-2;
  				}
  				&_value {
					font-size: 0.875rem;
  				}
  			}
  			
  			&__transition-actions {
  				@include clearfix;
  				width: 100%;
  				min-height: 35px;
	 			float: left;
  				&_document, 
  				&_search-again {
  					float :right;
  					.document__button, 
  					.search-again__button {
	  					color: $light;
	    				background-color: $brand-color-2;
	    				padding: .525rem;
	    				margin-right: .125rem;
						&:disabled {
						   cursor: not-allowed;
						   opacity: 0.65;
						   box-shadow: none;
						}
	    			}
  				}
			}
			
			&__hotel-overview, &__sightseeing-overview, &__air-overview, &__packages-overview, &__miscellaneous-overview, &__insurance-overview {
				@include clearfix;
				
				&_hotel-details, &_sightseeing-details, &_flight-details, &_packages-details, &_miscellaneous-details, &_insurance-details {
					width: 50%;
					float: left;
					padding: 0.5rem 0.5rem 0rem 0rem;
				}
				&_miscellaneous-details{
					width: 100%;
				}
				&_hotel-duration, &_sightseeing-duration, &_insurance-duration, &_sector-details {
					width: 50%;
	    			float: right;
					padding: 0.5rem 0 0rem 0.5rem;
				} 
			}
			&__hotel-price-detail {
				margin : 0.5rem 0;
			}
			
			&__cancellation {
				margin-top: 1rem;
				
				&_title {
					font-size: 1.15rem;
					color: $brand-color-2;
				}
				&_value {
					padding-bottom: 0.725rem;	
				}
			}
			
			&__pax-info {
				margin-top: 1rem;
				
				&_container {
					border: 2px solid $gray-lighter;
					margin-bottom: 0.5rem;
				}
			}
			&__important-notice{
				margin-bottom : 0.725rem;
				padding: 0.725rem;
				border: 0.625rem solid $brand-color-3;
			}
			
			&__tax-detail {
				margin : 0.5rem 0;
				
				.tax-detail {
					@include create-pricing-content;
				}
			}
  		}
  		
  		.product-container {
  			position: relative;
  			
  			&__checkbox {
	  			position: absolute;
	  			left: -3%;
	  			top: 20px;
  			}
  		}
  	}
  	&_loader{
  		background-color: $light;
  		padding: 1.725rem;
    	margin-top: 1rem;
		text-align:center;
		font-size: 1rem;
  	}
  	&_wrapper {
  		.alert__message--error, .alert__message--success {
  			margin-top: 1rem;
  			width:75%;
  		}	
  	}
}

.booking__title {
	 @include clearfix;
      border-bottom: 1px solid $brand-color-2;
      text-transform: uppercase;
      color: $brand-color-2;
      font-size: 20px;
	  margin-top: .375rem;
	  &_status-label{
	  	    float: right;
    		margin-right: 10rem;
    		color:rgb(0, 0, 205);
	  }
}

.credit-card-error{
	width: 3%;
}

.booking__overview {
	@include clearfix;
	font-size: 0.875rem;
	
	&_left-block {
		float: left;
	}
	
	&_right-block {
		float: right;
		text-align: right;
	}
	
	.customer {
		&__notes-label {
			color: $brand-color-2;
			padding: 0.625rem 0.625rem 0 0;
			float: left;
		}	
		&__notes-description {
			float: left;
		    font-family: $font-family-number;
		    padding-right: 0.625rem;
		    padding-top: 0.625rem;
		    min-height: 1px;
		}
	}
	
	&_left-block, &_right-block {
		width: 50%;
		.overview-block {
			float: right;
			
			&__title {
				color: $brand-color-2;
			}
			&__separator{
  				float:left;
	  		}
			&__title, &__value, &__separator {
				float: left;
				padding: 0.625rem 0.625rem 0 0;
				min-height: 1px;
			}
		} 
		.right-block{
			float: right;
		}
		.left-block, .right-block {
			&__overview-block {
				@include clearfix;
				width: 100%;
				&_title {
					color: $brand-color-2;
				}
				&_separator{
	  				float:left;
		  		}
		  		&_currency {
					float: left;
					padding-top: 0.825rem;
					padding-right: 0.25rem;
					min-height: 1px;
					font-size: 0.75rem;
		  		}
				&_title, &_value, &_separator  {
					float: left;
					padding: 0.625rem 0.625rem 0 0;
					min-height: 1px;
				}
				&_value{
					font-family: $font-family-number;
					.rfq-fare-msg{
						color:$brand-color-9;
						font-size: $font-size-h7;
						font-weight:900;
					}
					.vat-info-msg{
						color:$brand-color-9;
						font-size: $font-size-h4;
						font-weight:900;
					}
				}
			}		
		}
	} 
	
	
	&_transaction-date {
		float: right;
		margin-right: 5px;	
		text-align: right;
	}
	&_booking-invoice{
		padding-right: 0.625rem;
		padding-top: 0.625rem;
			.booking-invoice__link{
				float: left;
		    	font-family: $font-family-number;
		    	color: $brand-color-1;
			}
	}
}
.transaction-status{
	text-transform: uppercase;
}


.air, .hotel, .transfer, .common, .sightseeing, .packages, .miscellaneous, .insurance {
	@include create-booking-detail-card;
}


.reinitiate-ticketing, .cancel-request-form, .voucher-form, .dispatch-form, .confirm-form, .cancel-product-form ,.request-revalidate,.air-lcc-service,.air-ssr-service,.split-pnr-form, .reissue-request, .air-revert-reissue-form {
	@include create-transition-form;
}
.pax-selection {
	&__field {
		&_label-pax {
			margin-left:1rem;
		}
		&_value {
			color: $dark-gray;
		}
	}	
}

.air-revert-reissue-form {
	&__control-buttons {
		padding-top: 0.5rem;
    	padding-bottom: 1rem;
    	margin-left: 1rem;
	}	
	&__field {
		margin-left: 1.5rem;
	}	
}

.number {
	font-family: $font-family-number;
}
.user-action-controls__wrapper{
	@include clearfix;
	float:right;
	&_user-action-controls {
		float:left;
	    padding-right: 0;
		&__action {
			@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
			padding: 0.525rem;
    		margin-right: 0.125rem;
		}
	}
}
.booking__transition_modal {
	.interstitial{
		&__progress{
			padding-top: 8rem;
		}
	}
}

.booking-summary-header, .product-summary, .transaction-summary-header ,.accordion-card ,.accordion-card-b2c{
	padding: .9375rem;
	background-color: $light;
	margin-bottom: .9375rem;
	box-shadow: 0 0 15px rgba(0,0,0,.25);
	> a {
			color: $brand-color-1;
			&:hover{
				cursor: pointer;
			}	
		}
	
	.alert__message--success, .alert__message--error {
		width: 100%;
	}
	
	&__document-details-container {
		@include clearfix;
		margin-bottom: .5rem;
		width: 100%;
	}
	
}

.transaction-summary-header{
	&__action-buttons {
		@include clearfix;
		margin-top: 0.25rem;
		
		&_pay-button {
			float: right;
			min-height: 1px;
			margin-right: 0.125em;
		}
		&_add-more-product{
		    float: right;
		    
		    .dropdown-menu{
				min-width: 100%;
				cursor: pointer;
			}
			.dropdown-menu li:focus, .dropdown-menu li:hover {
			    color: $brand-color-5;
			    background-color:  $brand-color-4;
			}
			.dropdown-menu li{
			    display: block;
			    padding: 0.188rem 1.25rem;
			    font-weight: 400;
			    line-height: 1.25rem;
			    color: $brand-color-5;
			}
			.open .dropdown-toggle.btn-primary {
			   color: $brand-color-23;
			   background-color: $brand-color-10;
			}
		}
		&_add-more-products-container {
			float: right;
			width: 80%;
			min-height: 1px;
			margin-left: 0.1rem;
			.add-more-products-container__add-product-button {
				float: right;
			}
			
			.dropdown-menu{
				min-width: 100%;
				cursor: pointer;
			}
			.dropdown-menu li:focus, .dropdown-menu li:hover {
			    color: $brand-color-5;
			    background-color: $brand-color-4;
			}
			.dropdown-menu li{
			    display: block;
			    padding: 3px 20px;
			    font-weight: 400;
			    line-height: 1.42857143;
			    color: $brand-color-5;
			}
			.open .dropdown-toggle.btn-primary {
			   color: $light;
			   background-color: $brand-color-10;
			}
		}
	&_task-count{
		float: right;
        width: 1.25rem;
        height: 1.25rem;
        background-color: #fff;
        color: #555;
        border-radius: .9375rem; 
        border: .0625rem solid #555;
        font-family: Open Sans;
        font-size: .75rem;
        padding-top: .4rem;
        margin-left: 0.4rem;
        line-height: .5;
	}
	&_view-task{
		float: right;
	}
	
		button {
			@include button-size(6px ,12px, 14px, 20px, 4px);
			@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
		}
	}
	&__status-change-container{
		@include clearfix;
		padding: 0.725rem;
		border: 10px solid $brand-color-3;
		margin-bottom: 0.725rem;
		font-family: $font-family-number;
		&_heading{
			float: left;
		   	font-size: 1.25rem;
			border-bottom: 1px solid $brand-color-3;
			padding-bottom: 0.5rem;
			margin-bottom: 0.5rem;
			width: 100%;
			color: $water-blue;
			text-align: center;	
		}
		&_label{
			margin-left: 1rem;
			color: $water-blue;
		}
		&_status-box-buttons{
			width:60%;
			margin-left: 0.5rem;
			.status-change-container__select-box{
				> select {
				    width: 92%;
				    margin-left: 0.5rem;
				    height: 34px;
				    padding: 6px 12px;
				    background-color: #fff;
				    background-image: none;
				    border: 1px solid #ccc;
				    -webkit-border-radius: 4px;
				    border-radius: 4px;
				    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
				    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
				    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s;
				}
			}
			.status-change-container__buttons{
				@include clearfix;
				&_save, &_close {
					@include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
					float: left;
					margin-left: 0.5rem;
		    		margin-right: 0.125rem;
		    		margin-top: 0.525rem;
					padding: 0.525rem;
					width: 45%;
				}
			}
		}
	}
}

.accordion-card-b2c, .accordion-card{
	padding: 0.4rem 0.9rem;
	.product-summary{
	a{
	 &:hover{
              cursor: pointer;
               text-decoration: underline;
             }
	}
		@include clearfix;
		box-shadow: 0 0 0;
		&__title{
			color: $water-blue;
		}
		&__value, &__title{
			float: left;
			padding: 0.625rem 0.625rem 0 0;
			min-height: 0.0625rem;
		}
		&__value:hover {
    		text-decoration: underline;
		}
	}
}
.overview-header__label {
	font-size: 1.9rem
}
.accordion-card,.accordion-card-b2c{
	.panel-heading{
		a{
			color: $dark-gray;
    		text-decoration: none;
		}
		a:focus, a:hover{
			outline: none;
		}
	}
	h4{
	margin-bottom:0;
	}
}
.accordion-icon{
	float:left;
	padding-top: .25rem;
	width: 1rem;
}
.accordion-icon-b2c{
	float:left;
	margin-top: 0.9rem;
	width: 1rem;
}
.booking-product__overview_status-b2c{
	float:right;
	margin-top:1rem;
}

.supplier-error{
    float: left;
    font-size: 1rem;
  	 a{
  		color: $brand-color-2;
  		cursor: pointer;
  	}
  	&__error_message{
  		font-size: 0.750rem;
  		color: $brand-color-1;
  	}
  	&__warning_message{
  		font-size: 0.750rem;
  		color: $brand-color-11;
  	}
}
.supplier-error-block{
	margin-bottom: 3rem;
}
.header-details{
	&__address{
		margin-bottom: 0.3rem;
		word-wrap: break-word;
	}
}

.product-badge {
	background: $light;
    color: #000;
}

.cancellation-charge-details {
	&__details-block {
		font-size: 0.875rem;
		font-weight: 700;
		width: 100%;
		color: $brand-color-2;
		@include clearfix;
		
		&_heading {
			float: left;	
			margin-bottom: 1rem;
		}

		&_title {
			float: left;	
			width: 2.5rem;
			margin-left: 1rem;
		}
		&_separator{
			float: left;
			margin-left: .25rem;
			margin-right: .25rem;
		}
		&_value  {
			float: left;
			margin-left: .25rem;
			margin-right: .25rem;
			color: $brand-color-5;
		}
	}
	&__cancellation-details{
		&_header,
		&_header-pax-name {
			@include clearfix;
			background-color:  $brand-color-2;
		}
		&_header > div,
		&_header-pax-name > div {
			color:  $brand-color-3;
			text-align: left;
			font-weight: 700;
		}
		&_content,
		&_content-pax-name {
			box-shadow: $brand-color-22 0.15rem 0.15rem 0.2rem;
			display: table;
			width: 100%;
		}
		&_header > div,
		&_content > div,
		&_header-pax-name > div,
		&_content-pax-name > div {
			@include clearfix;
			float: left;
			font-size: 0.875rem;
			padding: 0.5rem;
			width: 25%;
			word-break: break-word;
			text-align: left;
		}
		&_header-pax-name > div,
		&_content-pax-name > div {
			width: 19%;
		}
	}
}