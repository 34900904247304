.hotel {
	&__booking {
		&_room-info {
			@include clearfix;
			border: 2px solid $gray-lighter;
		    font-size: 1.25rem;
			padding: .5rem 1rem;
			.room-info {
				&__lead-pax {
    				display: inline-block;
					&_name {
						@include text-overflow;
					}
					width: 25%;
				}
				&__room-details {
					color: $brand-color-2;
					float: left;
					width: 50%;
					text-transform: capitalize;
					&_room-category {
						text-transform: uppercase;
					}
				}
				&__price {
					@include clearfix;
					float: left;
					width: 50%;
					&_details {
						@include clearfix;
						float: right;
						&_currency {
							float: left;
						    font-size: .875rem;
						    margin-right: .3125rem;
		   					margin-top: .375rem;
						}
						&_amount {
							color: $brand-color-1;
							float: left;
							font-family: $font-family-number;
						    font-size: 1.25rem;
						    font-weight: 200;
						}
						&_expander{
						    cursor: pointer;
					        font-size: 1.5rem;
					        float: right;
						    min-width: 25px;
						    margin-left: .5rem;
						    margin-top: -3px;
						}
						&_info-icon{
							color : $brand-color-31;
						}
					}
				}
			}
			&_price-breakup {
				border: 2px solid $brand-color-3;
			    margin: .5rem 0;
			}
		}
	}
}