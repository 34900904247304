@use "sass:math";

.select-remark-form{
	padding: 0.725rem;
	border: 0.625rem solid $brand-color-3;
	margin-bottom: 0.725rem;
	font-family: $font-family-number;
	&__heading-container {
		@include clearfix;
		
		&_heading {
			float: left;
	   		font-size: 1.25rem;
		    border-bottom: 0.063rem solid $brand-color-3;
		    padding-bottom: 0.5rem;
		    margin-bottom: 0.5rem;
		    width: 100%;
		    color: $brand-color-2;
		    text-align: center;
		    
		    &__close-button {
				float: right;
			}
		}
	}
	
	&__footer-btn {
			.save-btn, .close-btn {
			  display: table;
			  content: '';
			  padding: 0.625rem 8rem 0.625rem 8rem;
			  background: $brand-color-1;
			  color: $brand-color-23;
			  margin-right: 0.635rem;
			  cursor: pointer;
			  
			  &:hover {
					color: $brand-color-23;
				    text-decoration: none;
				    background-color: darken($brand-color-1, 20);
			  }
			  
			}
			.save-btn {
				float: left;
				
				&:disabled {
					cursor: not-allowed;
			    	opacity: 0.65;
			    	box-shadow: none;
			  	}
			}
	}
	
	&__message {
		text-align: center;
	}
		
	&__details{
		 
		 &_fare-rule-table{
		     padding-bottom: 0.5rem;
	   		
	   		.fare-rule-table{
	     		width:100%;
				text-align: left;
				 
				    &__rule-header{
   						margin-bottom: 0.625rem;
   						border-radius: 0.625rem 0.625rem 0 0;
   						
   						&_title{
   					 		width: auto;
    						padding: math.div($base-padding, 6);
							font-weight: bold;
							font-size: $font-size-h5;
							text-transform: uppercase;
							background-color: $brand-color-2;
							color: $brand-color-23;
							box-sizing: border-box;
							margin-left: 3.5rem;
   						}
					}
					
					&__rule-details{
						
						&_rule{
						 	     background-color: $brand-color-4;
								 margin-bottom: math.div($base-padding, 6);
								 width: 100%;
						 		 text-transform: uppercase;
   							     display: block;
						 	.rule{
						 		&__sub-row, &__row{
						 		 	&_rule-checkbox, &_rule-sub-checkbox{
   										display: inline-block;
   										padding-left: 1.2rem;
    									padding-right: 1.2rem;
    									width: 5%;
    									background-color: $brand-color-23;
   									 }
   									 
   									 &_rule-title, &_rule-sub-title{
    									display: inline-block;
   									    font-weight: bold;
    									color: $brand-color-2;
    									font-size: 0.75rem;
    									width: 94%;
    									padding-left: 0.5rem;
   									 }
						 		}
						 		
						 		&__sub-row{
   									 &_rule-title{
   									    font-weight: bold;
    									color: $brand-color-2;
   									 }
						 		}
						 		&__row{
   									 
   									 &_rule-sub-title{
										background-color: $brand-color-23;
										color: $brand-color-19;
										font-weight: 500;
    									padding-top: 0.4rem;
   									 }
						 		}
						 	}
						}
					}
	   			}
		  }
   	}
}